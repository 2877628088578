import SETTINGS from "../../settings";

import './DonateLink.css';

function DonateLink() {
    const { DONATE_URL } = SETTINGS;

    return (<div>
        <a href={DONATE_URL} target='_blank' className='donate'>Поддержать проект</a>
    </div>);
}

export default DonateLink;

