import { useContext, useState, useEffect } from "react";
import { MediatorContext } from "../../App";
import LoginForm from "./LoginForm/LoginForm";
import SelectMap from "./SelectMap/SelectMap";
import SelectTile from "./SelectTile/SelectTile";
import MapEditor from "./MapEditor/MapEditor";

import './CustomMaps.css';

function CustomMaps() {
    const mediator = useContext(MediatorContext);
    const { USER_ADMIN_LOGIN } = mediator.getEventTypes();
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        const userAdminLoginHandler = data => setIsLogin(data);

        mediator.subscribe(USER_ADMIN_LOGIN, userAdminLoginHandler);

        return () => {
            mediator.unsubscribe(USER_ADMIN_LOGIN, userAdminLoginHandler);
        }
    });


    if (!isLogin) {
        return (<LoginForm />)
    }

    return (<div className="custom-maps">
        <SelectMap />
        <SelectTile />
        <MapEditor />
    </div>)
}

export default CustomMaps;