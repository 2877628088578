import sprites from '../../../assets/rebeltanks40x40.png';

type TCount = {
    [key: string]: number;
}
type TFrame = (id: string) => number[];

const getSpriteFromFrames = (frames: number[][]) => {
    let i = 0;
    const counts: TCount = {};
    return (id: string): number[] => {
        if (id) {
            if (counts[id] >= 0) {
                counts[id]++;
                if (counts[id] >= frames.length) {
                    counts[id] = 0;
                }
            } else {
                counts[id] = 0;
            }
            return frames[counts[id]];
        }

        i++;
        if (i >= frames.length) {
            i = 0;
        }
        return frames[i];
    }
}

const useSprites = (options: { SPRITE_SIZE: number, SIZE: number }): [HTMLImageElement, number[][][], number[][], TFrame[]] => {
    const { SPRITE_SIZE, SIZE } = options;
    const img = new Image();
    img.src = sprites;

    const grass = [
        [SPRITE_SIZE, SIZE * 5, SIZE * 2, SIZE], // травка
        [SPRITE_SIZE, SIZE * 3, SIZE * 2, SIZE], // осколки стены
    ];
    const walls = [
        [SPRITE_SIZE, SIZE * 0, SIZE * 2, SIZE],
        [SPRITE_SIZE, SIZE * 1, SIZE * 2, SIZE],
        [SPRITE_SIZE, SIZE * 2, SIZE * 2, SIZE],
        //[SPRITE_SIZE, SIZE * 3, SIZE * 2, SIZE], // будет частью проходимого грунта
    ];
    const bush = [
        [SPRITE_SIZE, SIZE * 6, SIZE * 2, SIZE], // кустарник
    ];
    const stone = [SPRITE_SIZE, SIZE * 4, SIZE * 2, SIZE];
    const redBase = [SPRITE_SIZE, SIZE * 3, SIZE * 1, SIZE];
    const blueBase = [SPRITE_SIZE, SIZE * 2, SIZE * 1, SIZE];
    const bullet = [SPRITE_SIZE, SIZE * 3, SIZE * 0, SIZE];
    const boom = getSpriteFromFrames([
        [SPRITE_SIZE, SIZE * 5, SIZE * 0, SIZE],
        [SPRITE_SIZE, SIZE * 6, SIZE * 0, SIZE],
        [SPRITE_SIZE, SIZE * 7, SIZE * 0, SIZE],
    ]);
    const redTank = getSpriteFromFrames([
        [SPRITE_SIZE, SIZE * 0, SIZE * 1, SIZE],
        [SPRITE_SIZE, SIZE * 1, SIZE * 1, SIZE],
    ]);
    const blueTank = getSpriteFromFrames([
        [SPRITE_SIZE, SIZE * 0, SIZE * 0, SIZE],
        [SPRITE_SIZE, SIZE * 1, SIZE * 0, SIZE],
    ]);
    return [
        img, [
            grass, walls, bush,
        ], [
            stone, redBase, blueBase, bullet
        ], [
            redTank, blueTank, boom
        ]
    ];
}

export default useSprites;