import SETTINGS from '../../../../settings';
import DonateLink from '../../../../components/DonateLink/DonateLink';

import './footer.css';

function Footer() {
    const { EMAIL } = SETTINGS;

    return (
        <div>
            <img className='wave' />
            <div className='footerLinkContainer'>
                <div className='footerEmailContainer'>
                    <p className='footerTextContent'>{EMAIL}</p>
                    <DonateLink />
                </div>
            </div>
        </div >
    );
}

export default Footer;