const SETTINGS = {
    // Prod
    HOST: 'https://rebeltanks.ru',
    // Dev
    // HOST: `${window.location.protocol}//${window.location.hostname}:3000`,

    EMAIL: 'alxtrusov@gmail.com',
    DONATE_URL: 'https://boosty.to/alxtrusov/donate',

    USER_TYPES: {
        ADMIN: 'admin',
    },

    KEY_APPENDIX: 'but my wife has blue eyes',
    AUTH_APPENDIX: 'some very important value',

    SPRITE_SIZE: 40,
    // игровое окно
    WINDOW: {
        LEFT: 0,
        BOTTOM: 0,
        HEIGHT: 18,
        WIDTH: 32
    },
    TILES: {},

    MESSAGES: {
        // about user
        USER_REGISTRATION: 'USER_REGISTRATION',
        USER_AUTO_LOGIN: 'USER_AUTO_LOGIN',
        USER_GET_INFO: 'USER_GET_INFO', // получить информацию юзера о нём самом
        USER_ADMIN_LOGIN: 'USER_ADMIN_LOGIN', // авторизуемся явно за админа
        // about game
        GET_TILES: 'GET_TILES', // получить список тайлов, используемых в игре
        // about admin
        ADMIN_GET_MAPS: 'ADMIN_GET_MAPS', // запрашиваем список карт с бекенда
        ADMIN_SET_MAP_PROPERTY: 'ADMIN_SET_MAP_PROPERTY', // поменять основные свойства карты
        ADMIN_ADD_NEW_MAP: 'ADMIN_ADD_NEW_MAP', // добавить новую карту
        ADMIN_GET_MAP: 'ADMIN_GET_MAP', // получить карту для редактирования
        ADMIN_ADD_SPRITE: 'ADMIN_ADD_SPRITE', // добавить на карту спрайт
        ADMIN_DEL_SPRITE: 'ADMIN_DEL_SPRITE', // удалить с карты спрайт
    },

    MEDIATOR: {
        EVENTS: {},
        TRIGGERS: {
            ACTIVE_TILE: 'ACTIVE_TILE', // выставить активный тайл
        }
    },
};

export default SETTINGS;