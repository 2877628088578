import { useEffect, useContext } from "react";
import { ServerContext, MediatorContext } from "../../../App";
import SETTINGS from "../../../settings";
import { useCanvas } from "../../../services/canvas";
import useSprites from "../hooks/useSprites";

const { SPRITE_SIZE, WINDOW, TILES } = SETTINGS;

const GAME_FIELD = 'game-field';

function MapEditor() {
    const server = useContext(ServerContext);
    const mediator = useContext(MediatorContext);
    const { ADMIN_GET_MAP } = mediator.getEventTypes();
    const { ACTIVE_TILE } = mediator.getTriggerTypes();
    let mapId = null;
    let map = [];
    let x = 0;
    let y = 0;

    // инициализация канваса
    let canvas = null;
    const Canvas = useCanvas(render);
    const [img, [grass, walls, bush], [stone, redBase, blueBase],] = useSprites({ SPRITE_SIZE, SIZE: SPRITE_SIZE }); // инициализация карты спрайтов

    function printSprite(canvas, x, y, points, direction) {
        if (direction) {
            canvas.spriteDir(img, x, y, points[0], points[1], points[2], points[3], direction);
            return;
        }
        canvas.spriteMap(img, x, y, points[0], points[1], points[2], points[3]);
    }

    // функция отрисовки одного кадра сцены
    function render() {
        if (canvas) {
            const CELL = 1;
            canvas.clear(); // очищает сцену
            // сначала рисуем просто травку
            for (let y = WINDOW.BOTTOM; y < WINDOW.HEIGHT; y++) {
                for (let x = WINDOW.LEFT; x < WINDOW.WIDTH; x++) {
                    printSprite(canvas, x, y + CELL, grass[0]); // в любом случае рисуем фоном, потому что по ней катаются все остальные
                }
            }
            // рисуем прилетевшую карту
            map && map.forEach(tile => {
                const { x, y, weight } = tile;
                if (weight === TILES.GRASS[1]) {
                    return printSprite(canvas, x, y + CELL, grass[1]);
                }
                if (TILES.WALL.includes(weight)) {
                    const wall = walls[walls.length - Math.floor(weight / 10)];
                    return printSprite(canvas, x, y + CELL, wall);
                }
                if (weight === TILES.STONE) return printSprite(canvas, x, y + CELL, stone);
                if (weight === TILES.RED_BASE) return printSprite(canvas, x, y + CELL, redBase);
                if (weight === TILES.BLUE_BASE) return printSprite(canvas, x, y + CELL, blueBase);
                if (weight === TILES.GRASS[2]) return printSprite(canvas, x, y + CELL, bush[0]); // рисуем кустарник
            });
            // рамка - указатель, где сейчас находится мышка
            canvas.polygon([{ x, y }, { x: x + 1, y }, { x: x + 1, y: y + 1 }, { x, y: y + 1 }]);
            canvas.render(); // отрендерить картинку
        }
    }

    const mouseMoveCanvas = (event) => {
        x = Math.floor(event.offsetX / SPRITE_SIZE);
        y = WINDOW.HEIGHT - Math.floor(event.offsetY / SPRITE_SIZE) - 1;
    }

    const mouseDownCanvas = (event) => {
        const mode = event.button === 0 ? 'add' : 'del';
        const x = Math.floor(event.offsetX / SPRITE_SIZE);
        const y = WINDOW.HEIGHT - Math.floor(event.offsetY / SPRITE_SIZE) - 1;
        if (mode === 'del' && mapId) {
            server.adminDelSprite(mapId, x, y);
            return;
        }
        const tileId = mediator.get(ACTIVE_TILE);
        if (mapId && tileId) {
            server.adminAddSprite(mapId, tileId, x, y);
        }
    }

    useEffect(() => {
        canvas = Canvas({
            parentId: GAME_FIELD,
            WIDTH: WINDOW.WIDTH * SPRITE_SIZE,
            HEIGHT: WINDOW.HEIGHT * SPRITE_SIZE,
            WINDOW,
            callbacks: {
                mousemove: mouseMoveCanvas,
                mousedown: mouseDownCanvas,
            }
        });

        const adminGetMapHandler = data => {
            if (data?.sprites) {
                mapId = data.id;
                map = data.sprites;
            }
        }

        mediator.subscribe(ADMIN_GET_MAP, adminGetMapHandler);

        return () => {
            mediator.unsubscribe(ADMIN_GET_MAP, adminGetMapHandler);
            canvas?.destructor();
            canvas = null;
        }
    }, [canvas]);

    return (
        <div id={GAME_FIELD} className='custom-element'></div>
    );
}

export default MapEditor;