import { useContext } from "react";
import { ServerContext } from "../../../../App";

function MapSettings({ map }) {
    const server = useContext(ServerContext);

    if (!map) {
        return (<div>Загрузка...</div>);
    }

    const { id, no, name, bonus, enemyCount, winCount } = map;

    const keyUpHandler = (event, property) => {
        const value = property === 'name' ? event.target.value : event.target.value - 0;
        map[property] = value;
        server.adminSetMapProperty(id, property, value);
    }

    return (<div>
        <div>
            <label>Настройки выбранной карты:</label>
        </div>
        <div>
            <table>
                <thead>
                    <tr>
                        <th scope="col">Номер</th>
                        <th scope="col">Название</th>
                        <th scope="col">Бонус</th>
                        <th scope="col">Количество врагов</th>
                        <th scope="col">Количество побед</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><input onKeyUp={(event) => keyUpHandler(event, 'no')} placeholder="no" defaultValue={no} /></td>
                        <td><input onKeyUp={(event) => keyUpHandler(event, 'name')} placeholder="name" defaultValue={name} /></td>
                        <td><input onKeyUp={(event) => keyUpHandler(event, 'bonus')} placeholder="bonus" defaultValue={bonus} /></td>
                        <td><input onKeyUp={(event) => keyUpHandler(event, 'enemyCount')} placeholder="enemyCount" defaultValue={enemyCount} /></td>
                        <td><input onKeyUp={(event) => keyUpHandler(event, 'winCount')} placeholder="winCount" defaultValue={winCount} /></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>);
}

export default MapSettings;