import md5 from 'md5';
import io from 'socket.io-client';
import SETTINGS from '../../settings';

export default class Server {
    constructor({ type, mediator }) {
        this.mediator = mediator;
        const { HOST, MESSAGES, USER_TYPES, KEY_APPENDIX, AUTH_APPENDIX } = SETTINGS;
        this.MESSAGES = MESSAGES;
        this.KEY_APPENDIX = KEY_APPENDIX;
        this.AUTH_APPENDIX = AUTH_APPENDIX;
        this.guid = null;; // guid юзера (постоянный)
        this.token = null; // сессионный токен юзера
        this.key = null; // админский специальный генерируемый ключ, чтобы не гонять важные данные на сервер
        // проинициализировать сокеты

        console.log(`try to connect to socket ${HOST}`);

        this.socket = io(`${HOST}`);
        this.socket.on('connect', () => {

            console.log(`connected!`);

            this.token = this.getData('token');
            this.guid = this.getData('guid');
            if (this.guid && this.token) { // попытка автоматической авторизации
                this._autoAuthEmit();
                return;
            }
            if (type === USER_TYPES.ADMIN) {
                this._registrationEmit(type); // автоматически регистрируемся, ибо этот уеблан не получился
            }
        });

        const { ADMIN_GET_MAPS, GET_TILES, ADMIN_GET_MAP } = mediator.getEventTypes();

        this.socket.on(this.MESSAGES.USER_REGISTRATION, data => this._userRegistrationOn(data));
        this.socket.on(this.MESSAGES.USER_AUTO_LOGIN, data => this._userAutoLoginOn(data));
        this.socket.on(this.MESSAGES.USER_ADMIN_LOGIN, data => this._userAdminLoginOn(data));
        this.socket.on(this.MESSAGES.ADMIN_GET_MAPS, data => mediator.call(ADMIN_GET_MAPS, data));
        this.socket.on(this.MESSAGES.GET_TILES, data => mediator.call(GET_TILES, data));
        this.socket.on(this.MESSAGES.ADMIN_GET_MAP, data => mediator.call(ADMIN_GET_MAP, data));
    }

    _checkGuidToken() {
        return this.guid && this.token;
    }

    /*******************/
    /* Support methods */
    /*******************/
    // генерирует guid
    genGuid() {
        function s4() { return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1); }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    // возвращает контрольную сумму хеша, сформированную по правилу hash = md5(token + строка(guid=значение;rnd=значение))
    getHash(token, params = {}) {
        return md5(token + Object.keys(params).sort().reduce((acc, key) => acc += params[key] ? `${key}=${params[key]};` : '', ''));
    }

    /*****************/
    /* Local storage */
    /*****************/
    getData(name) {
        return name ? localStorage.getItem(name) : null;
    }
    setData(name, value) {
        if (name) {
            localStorage.setItem(name, value);
        }
    }

    /*****************/
    /* Socket events */
    /*****************/
    _userRegistrationOn(data) {

        console.log('success registration', data)

        if (data) { // регистрация успешная, можно отправлять запрос на авторизацию
            this.setData('guid', this.guid); // записать в localStore
            this._autoAuthEmit();
        }
    }

    _userAutoLoginOn(data) {
        if (data) {
            this.setData('token', this.token); // записать в localStore
        }

        console.log('success auto login', data)
    }

    _autoAuthEmit() {
        if (this.guid) {
            const rnd = Math.round(Math.random() * 1000000);
            //TODO снижаем безопасность, чтобы на Ночи карьеры у всех хомяков всё нормально логинилось
            //this.token = md5(this.guid + (this.token || 'default token') + rnd);
            this.token = md5(`${this.guid}default token${rnd}`);
            const hash = this.getHash(this.token, { guid: this.guid, rnd });
            this.socket.emit(this.MESSAGES.USER_AUTO_LOGIN, { guid: this.guid, rnd, hash });
        }
    }

    _registrationEmit(type) {
        // почистить токен, потому что он нормально так может заруинить дальнейшую регистрацию и авторизацию
        this.token = null;
        this.setData('token', this.token); // записать в localStore
        // сгенерировать guid
        this.guid = this.genGuid();
        this.socket.emit(this.MESSAGES.USER_REGISTRATION, { guid: this.guid, type });
    }

    _userAdminLoginOn(data) {
        if (!data) {
            this.key = null; // в противном случае сбрасываем ключ
        }
        const { USER_ADMIN_LOGIN } = this.mediator.getEventTypes();
        this.mediator.call(USER_ADMIN_LOGIN, data);
    }

    /******************/
    /* Внешние методы */
    /******************/
    messageEmit(message, params = {}) {
        if (this._checkGuidToken()) {
            const rnd = Math.round(Math.random() * 1000000);
            const hash = this.getHash(this.token, { guid: this.guid, rnd, ...params });
            this.socket.emit(message, { guid: this.guid, rnd, hash, ...params });
        }
    }

    adminLogin(login, password) {
        const rnd1 = Math.round(Math.random() * 1000000);
        const rnd2 = Math.round(Math.random() * 1000000);
        this.key = md5(`${md5(`${login}${password}${rnd1}`)}${this.KEY_APPENDIX}`);
        const auth = md5(`${md5(`${login}${password}`)}${rnd2}${this.AUTH_APPENDIX}`);
        this.messageEmit(this.MESSAGES.USER_ADMIN_LOGIN, { auth, rnd1, rnd2 });
    }

    // проверить ключ и сформировать подпись админа по этому ключу
    _getKeyHash() {
        if (this.key) {
            const rndKey = Math.round(Math.random() * 1000000);
            const hashKey = md5(`${this.key}${rndKey}`);
            return { hashKey, rndKey };
        }
        return null;
    }

    // получить список всех кастомных карт
    adminGetMaps() {
        const hash = this._getKeyHash();
        if (hash) {
            this.messageEmit(this.MESSAGES.ADMIN_GET_MAPS, { ...hash });
        }
    }

    // узменить общие настройки карты
    adminSetMapProperty(id, property, value) {
        const hash = this._getKeyHash();
        if (hash) {
            this.messageEmit(this.MESSAGES.ADMIN_SET_MAP_PROPERTY, { id, property, value, ...hash });
        }
    }

    // добавить новую карту
    adminAddNewMap(no) {
        const hash = this._getKeyHash();
        if (hash) {
            this.messageEmit(this.MESSAGES.ADMIN_ADD_NEW_MAP, { no, ...hash });
        }
    }

    // получить тайлы
    getTiles() {
        const hash = this._getKeyHash();
        if (hash) {
            this.messageEmit(this.MESSAGES.GET_TILES, { ...hash });
        }
    }

    // получить карту для редактирования
    adminGetMap(no) {
        const hash = this._getKeyHash();
        if (hash) {
            this.messageEmit(this.MESSAGES.ADMIN_GET_MAP, { no, ...hash });
        }
    }

    // добавить/изменить спрайт
    adminAddSprite(mapId, tileId, x, y) {
        const hash = this._getKeyHash();
        if (hash) {
            this.messageEmit(this.MESSAGES.ADMIN_ADD_SPRITE, { mapId, tileId, x, y, ...hash });
        }
    }

    // удалить спрайт
    adminDelSprite(mapId, x, y) {
        const hash = this._getKeyHash();
        if (hash) {
            this.messageEmit(this.MESSAGES.ADMIN_DEL_SPRITE, { mapId, x, y, ...hash });
        }
    }
}