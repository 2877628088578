import { useEffect, useContext, useState } from "react";
import SETTINGS from "../../../settings";
import { ServerContext, MediatorContext } from "../../../App";
import useSprites from "../hooks/useSprites";

import './SelectTile.css';

const { SPRITE_SIZE, TILES } = SETTINGS;

function SelectTile() {
    const server = useContext(ServerContext);
    const mediator = useContext(MediatorContext);
    const { GET_TILES } = mediator.getEventTypes();
    const { ACTIVE_TILE } = mediator.getTriggerTypes();
    const [tiles, setTiles] = useState([]);
    const [img] = useSprites({ SPRITE_SIZE, SIZE: SPRITE_SIZE }); // инициализация карты спрайтов

    function getTilesByKey(tilesData, key) {
        return tilesData.reduce((S, value) => {
            if (S[value.key]) { // ключ уже есть
                if (S[value.key] instanceof Array) {
                    S[value.key].push(value[key]);
                } else {
                    S[value.key] = [S[value.key]].concat(value[key]);
                }
            } else {
                S[value.key] = value[key];
            }
            return S;
        }, {});
    }

    function setLocalTiles(tilesData) {
        const tiles = getTilesByKey(tilesData, 'weight');
        Object.keys(tiles).forEach(key => TILES[key] = tiles[key]);
    }

    useEffect(() => {
        if (tiles.length === 0) {
            server.getTiles(); // запросить тайлы с бекенда
        }

        const getTilesHandler = data => {
            setLocalTiles(data);
            setTiles(data);
        }

        mediator.subscribe(GET_TILES, getTilesHandler);

        return () => {
            mediator.unsubscribe(GET_TILES, getTilesHandler);
        }
    });

    if (tiles.length === 0) {
        return (<div>Загрузка...</div>);
    }

    // TODO самый херовый момент, потому что завязан на id
    const getClassName = (id) => {
        switch (id) {
            case 2: return 'fragments'; // осколки стены
            case 3: return 'bush'; // куст
            case 6: return 'wall'; // стена
            case 5: return 'wall-cracks'; // стена c стрещинами
            case 4: return 'wall-damaged'; // стена c проломами
            case 7: return 'stone'; // стена c проломами
            case 8: return 'red-base'; // красная база
            case 9: return 'blue-base'; // голубая база
            default: return null;
        }
    }
    
    const onClickSetActiveTile = (event, id) => {
        mediator.set(ACTIVE_TILE, () => id);
        // TODO Говнокооооод!!!
        document.querySelectorAll('.crop').forEach(elem => elem.classList.remove('active'));
        event.target.parentElement.classList.add('active');
    }

    return (<div className="custom-element">
        <div>Список тайлов:</div>
        <div className="tiles">
            {tiles.map((tile, index) => {
                const { id, name } = tile;
                const className = getClassName(id);
                return (className && <div key={index} className="crop">
                    <img onClick={(event) => onClickSetActiveTile(event, id)} className={className} alt={name} src={img.src} />
                </div>);
            })}
        </div>
    </div>);
}

export default SelectTile;