import DonateLink from '../../components/DonateLink/DonateLink';

import './helpPage.css';

function HelpPage() {
    return (
        <div className="helpPageContainer">
            <div className="helpPageDescription">
                <DonateLink />    
            </div>
        </div>
    );
}

export default HelpPage;