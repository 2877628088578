import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import useServer from './services/server/useServer';
import useMediator from './services/mediator/useMediator';
import NavBar from './components/NavBar/NavBar';
import { Main, Contacts, HelpPage, CustomMaps } from './pages';

import './App.css';

export const ServerContext = React.createContext();
export const MediatorContext = React.createContext();

function App() {
    const mediator = useMediator()();
    const server = useServer({ mediator })();

    return (<ServerContext.Provider value={server}>
        <MediatorContext.Provider value={mediator}>
            <NavBar />
            <Router>
                <Routes>
                    <Route exact path='/' element={<Main />} />
                    <Route exact path='/contacts' element={<Contacts />} />
                    <Route exact path='/help' element={<HelpPage />} />
                    <Route exact path='/custom' element={<CustomMaps />} />
                </Routes>
            </Router>
        </MediatorContext.Provider>
    </ServerContext.Provider>);
}

export default App;
