import { useContext, useRef } from "react";
import { ServerContext } from "../../../App";

import './LoginForm.css';

function LoginForm() {
    const server = useContext(ServerContext);
    const loginRef = useRef();
    const passwordRef = useRef();

    const clickHandler = () => {
        const login = loginRef?.current?.value;
        const password = passwordRef?.current?.value;
        if (login && password) {
            server.adminLogin(login, password);
        }
    }

    return (<div className="login-form">
        <h1>Авторизация</h1>
        <div>
            <input ref={loginRef} placeholder="login" />
        </div>
        <div>
            <input ref={passwordRef} placeholder="password" type="password" />
        </div>
        <button onClick={clickHandler}>Авторизоваться</button>
    </div>)
}

export default LoginForm;