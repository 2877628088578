enum EDIRECTION {
    LEFT = 'left',
    RIGHT = 'right',
    UP = 'up',
    DOWN = 'down',
};

type TWINDOW = {
    LEFT: number;
    BOTTOM: number;
    WIDTH: number;
    HEIGHT: number;
}

interface ICanvas {
    // контексты и канвасы
    canvas: HTMLCanvasElement;
    context: CanvasRenderingContext2D;
    canvasV: HTMLCanvasElement;
    contextV: CanvasRenderingContext2D;
    // общая ширина и высота канвасов
    WIDTH: number;
    HEIGHT: number;
    WINDOW: TWINDOW,
    DIRECTION: {
        [EDIRECTION.UP]: number,
        [EDIRECTION.DOWN]: number,
        [EDIRECTION.LEFT]: number,
        [EDIRECTION.RIGHT]: number,
    }
}

export type TCanvas = {
    parentId: string;
    WINDOW: TWINDOW;
    WIDTH: number;
    HEIGHT: number;
    callbacks: {
        mousemove: (event: MouseEvent) => void;
        mousedown: (event: MouseEvent) => void;
    };
}

class Canvas implements ICanvas {
    // контексты и канвасы
    canvas;
    context;
    canvasV;
    contextV;
    // общая ширина и высота канвасов
    WIDTH;
    HEIGHT;
    WINDOW; // окно
    PI2 = 2 * Math.PI; // Пи
    DIRECTION = {
        [EDIRECTION.UP]: -90 * Math.PI / 180,
        [EDIRECTION.DOWN]: 90 * Math.PI / 180,
        [EDIRECTION.LEFT]: 180 * Math.PI / 180,
        [EDIRECTION.RIGHT]: 0,
    }

    constructor(options: TCanvas) {
        const {
            parentId,
            // размеры по дефолту (на весь экран на стандартный размер)
            WIDTH = 32 * 60, // 1920
            HEIGHT = 18 * 60, // 1080
            WINDOW,
            callbacks,
        } = options;
        // задаём канвасы
        this.canvas = document.createElement('canvas');
        if (parentId) {
            document.getElementById(parentId)?.appendChild(this.canvas);
        } else {
            document.querySelector('body')?.appendChild(this.canvas);
        }
        this.canvas.addEventListener('mousemove', (event) => callbacks.mousemove(event));
        this.canvas.addEventListener('mousedown', (event) => callbacks.mousedown(event));
        this.canvas.addEventListener('contextmenu', (event) => event.preventDefault());

        // ширина и высота канваса
        this.WIDTH = WIDTH;
        this.HEIGHT = HEIGHT;
        // main canvas
        this.canvas.width = this.WIDTH;
        this.canvas.height = this.HEIGHT;
        this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D;
        // virtual canvas
        this.canvasV = document.createElement('canvas');
        this.canvasV.width = this.WIDTH;
        this.canvasV.height = this.HEIGHT;
        this.contextV = this.canvasV.getContext('2d') as CanvasRenderingContext2D;

        this.WINDOW = WINDOW;

        this.DIRECTION = {
            up: -90 * Math.PI / 180,
            down: 90 * Math.PI / 180,
            left: 180 * Math.PI / 180,
            right: 0,
        }
    }

    destructor() {
        // @ts-ignore
        this.contextV = null;
        // @ts-ignore
        this.canvasV = null;
        // @ts-ignore
        this.context = null;
        // @ts-ignore
        this.canvas = null;
    }

    // перевод в экранные координаты
    xs(x: number): number {
        return (x - this.WINDOW.LEFT) / this.WINDOW.WIDTH * this.WIDTH;
    }
    ys(y: number): number {
        return this.HEIGHT - (y - this.WINDOW.BOTTOM) / this.WINDOW.HEIGHT * this.HEIGHT;
    }

    clear(): void {
        this.contextV.fillStyle = '#000';
        this.contextV.fillRect(0, 0, this.canvasV.width, this.canvasV.height);
    }

    line(x1: number, y1: number, x2: number, y2: number, color = '#0f0', width = 2): void {
        this.contextV.beginPath();
        this.contextV.strokeStyle = color;
        this.contextV.lineWidth = width;
        this.contextV.moveTo(this.xs(x1), this.ys(y1));
        this.contextV.lineTo(this.xs(x2), this.ys(y2));
        this.contextV.stroke();
    }

    text(x: number, y: number, text: string, color = '#fff', font = 'bold 1rem Arial'): void {
        this.contextV.fillStyle = color;
        this.contextV.font = font;
        this.contextV.fillText(text, this.xs(x), this.ys(y));
    }

    point(x: number, y: number, color = '#f00', size = 2): void {
        this.contextV.beginPath();
        this.contextV.strokeStyle = color;
        this.contextV.arc(this.xs(x), this.ys(y), size, 0, this.PI2);
        this.contextV.stroke();
    }

    polygon(points: Array<{ x: number, y: number }>, color = '#080b'): void {
        this.contextV.fillStyle = color;
        this.contextV.beginPath();
        this.contextV.moveTo(this.xs(points[0].x), this.ys(points[0].y));
        for (let i = 1; i < points.length; i++) {
            this.contextV.lineTo(this.xs(points[i].x), this.ys(points[i].y));
        }
        this.contextV.closePath();
        this.contextV.fill();
    }

    sprite(image: HTMLImageElement, dx: number, dy: number, dSize: number, sx: number, sy: number, sSize: number): void {
        if (dSize && sx >= 0 && sy >= 0 && sSize) {
            this.contextV.drawImage(image, sx, sy, sSize, sSize, this.xs(dx), this.ys(dy), dSize, dSize);
            return;
        }
        if (dSize) {
            this.contextV.drawImage(image, this.xs(dx), this.ys(dy), dSize, dSize);
            return;
        }
        this.contextV.drawImage(image, this.xs(dx), this.ys(dy));
    }

    spriteBush(image: HTMLImageElement, dx: number, dy: number, dSize: number, sx: number, sy: number, sSize: number): void {
        this.contextV.drawImage(image, sx, sy, sSize, sSize, this.xs(dx), this.ys(dy), dSize, dSize);
    }

    // спрайт с поворотом
    spriteDir(image: HTMLImageElement, dx: number, dy: number, dSize: number, sx: number, sy: number, sSize: number, direction: EDIRECTION): void {
        if (direction && this.DIRECTION[direction] !== this.DIRECTION.right) {
            const xs = this.xs(dx);
            const ys = this.ys(dy);
            const transX = xs + dSize / 2;
            const transY = ys + dSize / 2;
            this.contextV.save();
            this.contextV.translate(transX, transY);
            this.contextV.rotate(this.DIRECTION[direction] || this.DIRECTION.right);
            this.contextV.translate(-transX, -transY);
            this.contextV.drawImage(image, sx, sy, sSize, sSize, xs, ys, dSize, dSize);
            this.contextV.restore();
            return;
        }
        this.sprite(image, dx, dy, dSize, sx, sy, sSize);
    }

    // копируем изображение с виртуального канваса на основной
    render(): void {
        this.context.drawImage(this.canvasV, 0, 0);
    }

    spriteMap(image: HTMLImageElement, dx: number, dy: number, dSize: number, sx: number, sy: number, sSize: number): void {
        if (dSize && sx >= 0 && sy >= 0 && sSize) {
            this.contextV.drawImage(image, sx, sy, sSize, sSize, this.xs(dx), this.ys(dy), dSize, dSize);
            return;
        }
        if (dSize) {
            this.contextV.drawImage(image, this.xs(dx), this.ys(dy), dSize, dSize);
            return;
        }
        this.contextV.drawImage(image, this.xs(dx), this.ys(dy));
    }
}

export default Canvas;