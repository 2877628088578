import { useContext, useState, useEffect, useRef } from "react";
import { ServerContext, MediatorContext } from "../../../App";
import MapSettings from "./MapSettings/MapSettings";

import './SelectMap.css';

function SelectMap() {
    const server = useContext(ServerContext);
    const mediator = useContext(MediatorContext);
    const { ADMIN_GET_MAPS } = mediator.getEventTypes();
    const [maps, setMaps] = useState([]);
    const [map, setMap] = useState(null);
    const mapNoRef = useRef();

    // этот ПИДОР, оказывается, выстреливает при изменении состояния компоненты!
    // петух, мля...
    useEffect(() => {
        if (maps.length === 0) {
            server.adminGetMaps(); // запросить карты с бекенда
        }

        const adminGetMapsHandler = data => setMaps(data);

        mediator.subscribe(ADMIN_GET_MAPS, adminGetMapsHandler);

        return () => {
            mediator.unsubscribe(ADMIN_GET_MAPS, adminGetMapsHandler);
        }
    });

    if (maps.length === 0) {
        return (<div>Загрузка...</div>);
    }

    const onChangeSelectMapHandler = (event) => {
        const id = event.target.value - 0;
        const map = maps.find(map => map.id === id);
        if (map) {
            server.adminGetMap(map.no);
            setMap(map);
        }
    }

    const onClickAddNewMapHandler = () => {
        const no = mapNoRef?.current?.value - 0;
        if (no && maps.findIndex(map => map.no === no) === -1) {
            server.adminAddNewMap(no);
        }
    }

    return (<div className="custom-element">
        <div className="select-and-new-map">
            <div>
                <div>
                    <label>Список доступных кастомных карт:</label>
                </div>
                <select onChange={onChangeSelectMapHandler}>
                    {maps.map((map, index) => {
                        const { id, no, name } = map;
                        return (<option key={index} value={id}>{`${no}: ${name}`}</option>);
                    })}
                </select>
            </div>
            <div>
                <button onClick={onClickAddNewMapHandler}>Добавить новую карту</button>
                <input ref={mapNoRef} placeholder="no" />
            </div>
        </div>
        <MapSettings key={map?.id} map={map} />
    </div>);
}

export default SelectMap;